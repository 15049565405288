<template>
  <div id="app">
    <img alt="Vue logo" src="./assets/logo.svg">
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style lang="scss">

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  img {
    width: 200px;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: fadeInAnimation ease 10s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

}

// Small Devices
@media only screen and (max-width : 401px) {

  #app {

    img {
      width: 50%;
    }

  }

}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

</style>
